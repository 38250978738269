type Value = string | number

export const utils = {
  size: (value: Value) => ({ width: value, height: value }),
  marginX: (value: Value) => ({ marginLeft: value, marginRight: value }),
  marginY: (value: Value) => ({ marginTop: value, marginBottom: value }),
  paddingX: (value: Value) => ({ paddingLeft: value, paddingRight: value }),
  paddingY: (value: Value) => ({ paddingTop: value, paddingBottom: value }),
  stack: (value: Value) => ({
    display: 'flex',
    flexDirection: 'column' as const,
    '& > *:not(:last-child)': { marginBottom: value }
  }),
  stackH: (value: Value) => ({
    display: 'flex',
    flexDirection: 'row' as const, // row is default, but set explicitly here to override
    '& > *:not(:last-child)': { marginRight: value }
  })
} as const
