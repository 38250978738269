import * as categoriesSelectors from './categoriesSelectors'
import { authActions } from 'store/auth'
import { Category } from 'types/category'
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import {
  draftUpdatesInitialState,
  draftUpdatesReducers
} from 'store/draftUpdates'
import { OdoStore } from 'store/store'
import { setInitialState } from 'store/actions'
import { sortByRankAsc } from 'services/lexorank'
import { spacesActions } from 'store/spaces'

export const categoriesAdapter = createEntityAdapter<Category>({
  sortComparer: sortByRankAsc
})

const initialState = {
  ...categoriesAdapter.getInitialState(),
  ...draftUpdatesInitialState
}

export type CategoriesState = typeof initialState

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoriesReceived: categoriesAdapter.setAll,

    createCategoryRequested: categoriesAdapter.setOne,
    createCategorySucceeded: categoriesAdapter.setOne,
    createCategoryFailed: categoriesAdapter.removeOne,

    updateCategoryRequested: categoriesAdapter.updateOne,
    updateCategorySucceeded: categoriesAdapter.setOne,
    updateCategoryFailed: categoriesAdapter.setOne,

    updateCategoriesSucceeded: categoriesAdapter.setMany,

    removeCategoryRequested: categoriesAdapter.removeOne,
    removeCategorySucceeded: categoriesAdapter.removeOne,
    removeCategoryFailed: categoriesAdapter.setOne,

    changeCategoryOrderRequested: categoriesAdapter.updateMany,
    changeCategoryOrderSucceeded: categoriesAdapter.updateMany,
    changeCategoryOrderFailed: categoriesAdapter.updateMany,

    ...draftUpdatesReducers
  },
  extraReducers: builder => {
    builder
      .addCase(setInitialState, (state, action) => ({
        ...(action.payload.categories ? action.payload.categories : state)
      }))
      .addCase(authActions.userSignedOut, () => initialState)
      .addCase(spacesActions.selectedSpaceChanged, () => initialState)
  }
})

export const { actions, reducer } = categoriesSlice

export const adapterSelectors = categoriesAdapter.getSelectors<OdoStore>(
  state => state.categories
)

export const selectors = {
  ...adapterSelectors,
  ...categoriesSelectors
}

export const genericSelectors = categoriesAdapter.getSelectors()
