import Cookie from 'js-cookie'
import { createClient } from '@supabase/supabase-js'
import { DEFAULT_COOKIE_OPTIONS } from './constants'

if (
  !process.env.NEXT_PUBLIC_SUPABASE_URL ||
  !process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ||
  !process.env.NEXT_PUBLIC_SUPABASE_SESSION_COOKIE
) {
  throw new Error(
    'NEXT_PUBLIC_SUPABASE_URL, NEXT_PUBLIC_SUPABASE_ANON_KEY and NEXT_PUBLIC_SUPABASE_SESSION_COOKIE env variables are required!'
  )
}

export function createBrowserClient<
  Database = any,
  SchemaName extends string & keyof Database = 'public' extends keyof Database
    ? 'public'
    : string & keyof Database
>() {
  return createClient<Database, SchemaName>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      auth: {
        storageKey: process.env.NEXT_PUBLIC_SUPABASE_SESSION_COOKIE,
        storage: {
          getItem(key) {
            return Cookie.get(key) ?? null
          },
          setItem(key, value) {
            Cookie.set(key, value, {
              ...DEFAULT_COOKIE_OPTIONS,
              expires: 365,
              secure: window.location.protocol === 'https:'
            })
          },
          removeItem(key) {
            Cookie.remove(key)
          }
        },
        autoRefreshToken: true,
        detectSessionInUrl: true,
        persistSession: true
      }
    }
  )
}
