import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTagListFromLocalStorage } from 'utils/localStorage/tagList'
import { TagList } from 'types/tagList'
import { setInitialState } from 'store/actions'
import { selectByScreen } from './tagListSelectors'

export type Screens = 'log' | 'search'
type ViewType = TagList['type']

export type TagListState = {
  [K in Screens]: {
    type: ViewType
    initialMessageDismissed?: boolean
  }
}

type InitialMessageDismissed = { screen: Screens }
type ViewTypeChangedPayload = { screen: Screens; type: ViewType }

const tagListFromLocalStorage = getTagListFromLocalStorage()

const initialState: TagListState = tagListFromLocalStorage || {
  log: {
    type: 'grid',
    initialMessageDismissed: false
  },
  search: {
    type: 'grid'
  }
}

const tagListSlice = createSlice({
  name: 'tagList',
  initialState,
  reducers: {
    viewTypeChanged: (
      state,
      { payload }: PayloadAction<ViewTypeChangedPayload>
    ) => {
      state[payload.screen].type = payload.type
    },
    initialMessageDismissed: (
      state,
      { payload }: PayloadAction<InitialMessageDismissed>
    ) => {
      state[payload.screen].initialMessageDismissed = true
    }
  },
  extraReducers: builder => {
    builder.addCase(setInitialState, (state, action) => ({
      ...(action.payload.tagList ? action.payload.tagList : state)
    }))
  }
})

export const { actions, reducer } = tagListSlice

export const selectors = {
  selectByScreen
}
