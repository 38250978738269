import { getBreakpointValues } from '@sc/utils/getBreakpointValues'
import { desktop } from './stitches.config'

export const defaultGlobalStyles = {
  ':root': {
    fontSize: '62.5%', // Hack for 1rem to be 10px,

    '@media (min-width: 480px)': getBreakpointValues(desktop),

    // Radix vars
    '--radix-toast-viewport-bottom': '2rem'
  },

  'html, body': {
    padding: 0,
    margin: 0,
    height: '100%',
    backgroundColor: '$surfaceBg',
    '@media (prefers-reduced-motion: no-preference)': {
      transition: 'background 0.3s' // Nicer switch from light/dark mode
    }
  },

  'h1, h2, h3, h4, h5, h6': { fontWeight: 'normal' },

  a: {
    color: 'inherit', // never nasty blue links
    textDecoration: 'none'
  },

  '*': { boxSizing: 'border-box' },

  body: {
    fontFamily: '"Octave", sans-serif',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    color: '$text'
  },

  '#__next': {
    height: '100%'
  },

  ul: {
    padding: 0
  },

  p: {
    margin: 0
  },

  pre: {
    margin: 0
  },

  '@font-face': [
    {
      fontFamily: 'Octave',
      src: `url('/fonts/Octave-Regular.woff')`,
      fontWeight: 400
    },
    {
      fontFamily: 'Octave',
      src: `url('/fonts/Octave.woff')`,
      fontWeight: 500
    },
    {
      fontFamily: 'ABCOracleTriple',
      src: `url('/fonts/ABCOracleTriple-Light.woff')`
    }
  ]
}
