import { SWRConfig, mutate } from 'swr'

// Why unsafe ? SWR discourages accessing the cache directly because it could lead to race conditions when reading values.
// We are not reading the cache values, just the keys. It should 100% be fine!
// Safe way is to do mutate(() => true, undefined, { revalidate: false })
// But that does not work with the useSWRInfitite hooks.
export const unsafeFlushSWRCache = () => {
  for (const key of SWRConfig.defaultValue.cache.keys()) {
    mutate(key, undefined, { revalidate: false })
  }
}
