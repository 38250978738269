import { createSelector } from 'reselect'
import { OdoStore } from 'store'

const spaceSlice = (state: OdoStore) => state.spaces

export const currentSpaceId = createSelector(
  spaceSlice,
  spaces => spaces.currentSpaceId
)

export const spaces = createSelector(spaceSlice, spaces =>
  spaces.memberships.map(membership => membership.space)
)

export const spaceIds = createSelector(spaces, spaces =>
  spaces.map(space => space.id)
)

export const currentSpace = createSelector(
  spaces,
  currentSpaceId,
  (spaces, currentSpaceId) => spaces.find(space => space.id === currentSpaceId)
)

export const currentSpaceMembership = createSelector(
  spaceSlice,
  currentSpaceId,
  (slice, currentSpaceId) =>
    slice.memberships.find(membership => membership.space.id === currentSpaceId)
)

export const administratesCurrentSpace = createSelector(
  currentSpaceMembership,
  membership => membership && membership.accessLevel.includes('admin')
)

export const isLoading = createSelector(spaceSlice, slice => slice.loading)
