import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const DEFAULT_TIMEOUT = 60 * 1000

const alertsAdapter = createEntityAdapter({
  // use date as ID - unlikely 2 alerts will happen at the same microsecond
  selectId: alert => alert.date,
  sortComparer: (a, b) => a.date - b.date
})

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsAdapter.getInitialState(),
  reducers: {
    alert: alertsAdapter.upsertOne,
    alertDismissed: alertsAdapter.removeOne
  }
})

const { actions, reducer } = alertsSlice
const selectors = alertsAdapter.getSelectors(state => state.alerts)

export const alert = dispatch => ({ timeout = DEFAULT_TIMEOUT, ...alert }) => {
  const date = Date.now()
  dispatch(actions.alert({ date, ...alert }))
  setTimeout(() => {
    dispatch(actions.alertDismissed(date))
  }, timeout)
}

export const useAlert = () => {
  const dispatch = useDispatch()

  return useCallback(({ timeout = DEFAULT_TIMEOUT, ...alert }) => {
    const date = Date.now()
    dispatch(actions.alert({ date, ...alert }))
    setTimeout(() => {
      dispatch(actions.alertDismissed(date))
    }, timeout)
  }, [dispatch])
}

const useAlerts = () => {
  const dispatch = useDispatch()
  return useSelector(selectors.selectAll).map(alert => ({
    ...alert,
    dismiss: () => dispatch(actions.alertDismissed(alert.date))
  }))
}

const alerts = {
  reducer,
  useAlert,
  useAlerts
}

export default alerts
