import { datadogLogs } from '@datadog/browser-logs'
import { supabase } from './supabase'

datadogLogs.init({
  clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
  site: 'datadoghq.com',
  service: 'focus-web',
  env: process.env.NEXT_PUBLIC_VERCEL_ENV || 'local',
  version:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.substr(0, 8) || 'local'
})

// Add git info to logs
if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  datadogLogs.logger.addContext('git', {
    branch: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    commit: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    author: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN
  })
}

// Add user info to logs
supabase.auth.onAuthStateChange((event, session) => {
  if (session?.user) {
    datadogLogs.logger.addContext('user', {
      id: session.user.id,
      email: session.user.email
    })
  } else {
    datadogLogs.logger.removeContext('user')
  }
})

datadogLogs.logger.setHandler(['console', 'http'])

export const logger = datadogLogs.logger
