import { createSelector } from '@reduxjs/toolkit'
import { OdoStore } from 'store'

import { Screens } from './tagListSlice'

const tagListSlice = (state: OdoStore) => state.tagList

export const selectByScreen = createSelector(
  [tagListSlice, (state, screen: Screens) => screen],
  (tagList, screen) => {
    return tagList[screen]
  }
)
