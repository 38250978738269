import { setUser, configureScope, addBreadcrumb } from '@sentry/nextjs'
import type { User } from '@sentry/nextjs'

export const setUserToScope = (user: User) => {
  setUser(user)
  addBreadcrumb({
    category: 'auth',
    message: `Authenticated user ${user?.email}`,
    level: 'info'
  })
}

export const removeUserFromScope = () =>
  configureScope(scope => scope.setUser(null))
