import { SearchDatePreset } from 'types/search'
import { isSingleDay, isWholeMonth, isWholeWeek, isWholeYear } from './time'

export const guessPresetOfRange = (
  start: Date,
  end: Date
): SearchDatePreset => {
  if (isSingleDay(start, end)) return 'day'
  if (isWholeWeek(start, end)) return 'week'
  if (isWholeMonth(start, end)) return 'month'
  if (isWholeYear(start, end)) return 'year'
  return 'custom'
}
