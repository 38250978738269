import * as dateFns from 'date-fns'
import { createSelector } from '@reduxjs/toolkit'
import { OdoStore } from 'store'
import {
  isSingleDay,
  isWholeWeek,
  isThisWeek,
  isThisMonth,
  isWholeMonth,
  isWholeYear,
  isThisYear
} from 'utils/time'
import { EntriesGroupedByDay, TimeEntry } from 'types/timeEntry'
import { timeEntriesGenericSelectors, sortComparer } from 'store/timeEntries'
import { parseTextQueryToCollection } from 'components/Search/utils'

// Utils
const formatValidCollection = collection => {
  if (!collection.length) {
    return collection
  }

  if (collection.length % 2 !== 0) {
    return collection
  }

  return collection.slice(0, collection.length - 1)
}

// Selectors

const searchSlice = (state: OdoStore) => state.search

export const searchQuery = createSelector([searchSlice], ({ tags, query }) => ({
  description: query,
  labels: tags
}))

export const parsedSearchQuery = createSelector([searchSlice], state => {
  return formatValidCollection(parseTextQueryToCollection(state.query))
})

export const peopleQuery = createSelector([searchSlice], state => state.people)

export const searchRange = createSelector([searchSlice], state => ({
  dateStart: state.dateStart,
  dateEnd: state.dateEnd
}))

export const searchRangeKey = createSelector(
  [searchSlice],
  ({ dateStart, dateEnd }) => {
    if (isSingleDay(dateStart, dateEnd)) {
      return dateFns.isToday(dateStart)
        ? 'today'
        : dateFns.format(dateStart, 'd/M/y')
    }

    if (isWholeWeek(dateStart, dateEnd)) {
      return isThisWeek(dateStart, dateEnd)
        ? 'thisWeek'
        : isThisWeek(dateStart, dateEnd, { offset: 1 })
        ? 'lastWeek'
        : `${dateFns.format(dateStart, 'd/M/y')} - ${dateFns.format(
            dateEnd,
            'd/M/y'
          )}`
    }

    if (isWholeMonth(dateStart, dateEnd)) {
      return isThisMonth(dateStart, dateEnd)
        ? 'thisMonth'
        : isThisMonth(dateStart, dateEnd, { offset: 1 })
        ? 'lastMonth'
        : dateFns.format(dateStart, 'MMMM Y')
    }

    if (isWholeYear(dateStart, dateEnd)) {
      return isThisYear(dateStart, dateEnd)
        ? 'thisYear'
        : dateFns.format(dateStart, 'Y')
    }

    // Random range, just return a formatted label
    return `${dateFns.format(dateStart, 'd/M/y')} - ${dateFns.format(
      dateEnd,
      'd/M/y'
    )}`
  }
)

export const searchEntriesByDay = createSelector(
  [
    (state: OdoStore) => state.timeEntries,
    (_, entryIds: Array<TimeEntry['id']>) => entryIds
  ],
  (state, entryIds) =>
    entryIds
      .map(id => timeEntriesGenericSelectors.selectById(state, id))
      .filter(entry => Boolean(entry))
      .sort(sortComparer)
      .reduce<EntriesGroupedByDay>(function (map, entry) {
        if (!entry.dateEnd) return map

        const date = dateFns.format(entry.dateStart, 'yyyy-MM-dd')
        const seconds = dateFns.differenceInSeconds(
          entry.dateEnd,
          entry.dateStart
        )

        if (map[date]) {
          map[date].entries.push(entry.id)
          map[date].totalSeconds += seconds
        } else {
          map[date] = {
            entries: [entry.id],
            date: dateFns.startOfDay(entry.dateStart),
            totalSeconds: seconds
          }
        }

        return map
      }, {})
)
