import Fuse from 'fuse.js'
import { createSelector } from '@reduxjs/toolkit'
import { Tag, TagWithCategory } from 'types/tag'
import { activeTagsWithCategory, selectAll } from './tagsSelectors'

const logFuzzyOptions: Fuse.IFuseOptions<{}> = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  findAllMatches: true,
  keys: ['name'],
  threshold: 0.4,
  location: 0
}

const searchOptions = { ...logFuzzyOptions, shouldSort: true }

export const logFuzzyDictionary = createSelector(
  activeTagsWithCategory,
  tags => {
    const dictionary = Fuse.createIndex<TagWithCategory>(['name'], tags)
    return new Fuse<TagWithCategory>(tags, logFuzzyOptions, dictionary)
  }
)

export const searchFuzzyDictionary = createSelector(selectAll, tags => {
  const dictionary = Fuse.createIndex<Tag>(['name'], tags)
  return new Fuse<Tag>(tags, searchOptions, dictionary)
})
