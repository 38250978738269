import * as actions from './authActions'
import { AuthState, initialState } from './authState'
import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { setInitialState } from 'store/actions'

export const authReducer = createReducer<AuthState>(initialState, builder =>
  builder
    .addCase(setInitialState, (state, action) => ({
      ...(action.payload.auth ? action.payload.auth : state)
    }))
    .addCase(actions.userSignedOut, () => initialState)
    .addCase(actions.profileFetched, (state, action) => ({
      ...state,
      profile: action.payload
    }))
    .addCase(actions.profileUpdated, (state, action) => ({
      ...state,
      profile: {
        ...state.profile,
        ...action.payload
      }
    }))
    .addCase(actions.userSignedUp, (state, action) => ({
      ...state,
      profile: action.payload
    }))
    .addMatcher(
      isAnyOf(
        actions.userSignedIn,
        actions.userTokenRefreshed,
        actions.userInfoUpdated
      ),
      (state, { payload }) => ({
        ...state,
        session: {
          id: payload.user.id,
          email: payload.user.email
        }
      })
    )
)
