import { categoriesSelectors } from 'store/categories'
import { Category } from 'types/category'
import { createDeepEqualSelector } from 'store/createDeepEqualSelector'
import { createSelector } from '@reduxjs/toolkit'
import { genericSelectors } from './tagsSlice'
import { genericSelectors as categoryGenericSelectors } from 'store/categories/categoriesSlice'
import { OdoStore } from 'store'
import { Tag, TagWithCategory } from 'types/tag'

const tagsSlice = (state: OdoStore) => state.tags
const categoriesSlice = (state: OdoStore) => state.categories

const selectTagColorCorrected = (
  tagSlice: OdoStore['tags'],
  categoriesSlice: OdoStore['categories'],
  id: Tag['id']
) => {
  const tag = genericSelectors.selectById(tagSlice, id)

  if (!tag) return undefined

  const category = categoryGenericSelectors.selectById(
    categoriesSlice,
    tag.categoryId
  )

  return {
    ...tag,
    color: category && category.color ? category.color : tag.color,
    categoryName: category?.name
  }
}

export const selectByIdColorCorrected = createSelector(
  tagsSlice,
  categoriesSlice,
  (_: OdoStore, id: Tag['id']) => id,
  (tags, categories, id) => {
    return selectTagColorCorrected(tags, categories, id)
  }
)

export function selectByIdsFactory(ids: Array<Tag['id']>) {
  return createDeepEqualSelector(
    tagsSlice,
    categoriesSlice,
    (tags, categories) => {
      return ids
        .map(id => selectTagColorCorrected(tags, categories, id))
        .filter(tag => Boolean(tag))
    }
  )
}

export const selectAll = createSelector(
  tagsSlice,
  categoriesSelectors.categoriesColors,
  (tags, colors) => {
    return genericSelectors.selectAll(tags).map(tag => {
      if (tag.categoryId && colors[tag.categoryId]) {
        return {
          ...tag,
          color: colors[tag.categoryId]
        }
      }

      return tag
    })
  }
)

export const uncategorized = createSelector(tagsSlice, slice => {
  return genericSelectors
    .selectAll(slice)
    .filter(tag => !tag.archived && !tag.categoryId)
})

export const uncategorizedIds = createSelector(uncategorized, tags =>
  tags.map(tag => tag.id)
)

export const activeTags = createSelector(selectAll, tags => {
  return tags.filter(tag => tag.archived === false)
})

export const archived = createSelector(tagsSlice, slice => {
  return genericSelectors.selectAll(slice).filter(tag => tag.archived)
})

export const selectByCategory = createSelector(
  [selectAll, (_, id: Category['id']) => id],
  (tags, categoryId) => {
    return tags.filter(tag => tag.categoryId === categoryId && !tag.archived)
  }
)

export const activeTagsWithCategory = createSelector(
  tagsSlice,
  categoriesSlice,
  (tagsSlice, categoriesSlice) => {
    const categories = categoryGenericSelectors.selectEntities(categoriesSlice)

    return genericSelectors
      .selectAll(tagsSlice)
      .filter(tag => tag.archived === false)
      .map<TagWithCategory>(tag => {
        const category = tag.categoryId ? categories[tag.categoryId] : null

        return {
          ...tag,
          color: category && category.color ? category.color : tag.color,
          categoryName: category ? category.name : null
        }
      })
  }
)
