import { createAction } from '@reduxjs/toolkit'
import { Session } from '@supabase/supabase-js'
import { Profile } from 'types/profile'
import { withPayloadType } from '../withPayloadType'

const ACTION_PREFIX = 'auth/'

export const userSignedIn = createAction(
  `${ACTION_PREFIX}userSignedIn`,
  withPayloadType<Session>()
)

export const userTokenRefreshed = createAction(
  `${ACTION_PREFIX}userTokenRefreshed`,
  withPayloadType<Session>()
)

export const userSignedOut = createAction(`${ACTION_PREFIX}userSignedOut`)

export const userInfoUpdated = createAction(
  `${ACTION_PREFIX}userInfoUpdated`,
  withPayloadType<Session>()
)

export const profileFetched = createAction(
  `${ACTION_PREFIX}profileFetched`,
  withPayloadType<Profile>()
)

export const profileUpdated = createAction(
  `${ACTION_PREFIX}profileUpdated`,
  withPayloadType<Partial<Omit<Profile, 'id'>>>()
)

export const userSignedUp = createAction(
  `${ACTION_PREFIX}userSignedUp`,
  withPayloadType<Profile>()
)
