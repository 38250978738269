import { LOCAL_STORAGE_THEME_SELECTED_KEY, Themes } from 'types/themes'

export function isValidTheme(theme: string): theme is Themes {
  return (
    theme === Themes.LIGHT || theme === Themes.DARK || theme === Themes.SYSTEM
  )
}

export function getThemeFromLocalStorage(): Themes {
  const theme =
    typeof window !== 'undefined'
      ? localStorage.getItem(LOCAL_STORAGE_THEME_SELECTED_KEY)
      : Themes.SYSTEM

  return isValidTheme(theme) ? theme : Themes.SYSTEM
}

export function setThemeToLocalStorage(theme: Themes) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(LOCAL_STORAGE_THEME_SELECTED_KEY, theme)
  }
}

export function getSystemTheme() {
  if (typeof window !== 'undefined') {
    const media = window.matchMedia('(prefers-color-scheme: dark)')
    return media.matches ? Themes.DARK : Themes.LIGHT
  }

  return Themes.LIGHT
}
