import 'normalize.css'
import '@sc/assets/css/day-picker.css'
import '@sc/assets/css/time-picker.css'
import '../assets/css/fonts.css'
import '../i18n'
import '../services/datadog'

import Head from 'next/head'
import { useEffect, useState } from 'react'
import { store } from 'store'
import shimMatchall from 'string.prototype.matchall/shim'

import { TooltipProvider } from '@sc/components/Tooltip'
import { ErrorBoundary } from '@sentry/react'
import { MediaProvider } from 'hooks/useMedia'
import { useSupabaseAuth } from 'hooks/useSupabaseAuth'
import { useUpdateUrlWithSpace } from 'hooks/useUpdateUrlWithSpace'
import { useWatchSpace } from 'hooks/useWatchSpace'
import { LinkMetadataProvider } from 'modules/shared/Slate/LinkMetadataContext'
import { ThemeProvider, useTheme } from 'next-themes'
import { Provider, useSelector } from 'react-redux'
import { settingsSelectors } from 'store/settings'
import { globalStyles } from 'theme/stitches.config'
import { Themes } from 'types/themes'
import { getLanguage } from 'utils/getLanguage'
import { getSystemTheme } from 'utils/theme'
import { i18nInit } from '../i18n/i18n'
import { Analytics } from 'services/Analytics'
import Script from 'next/script'

shimMatchall()

const Providers = ({ children }) => (
  <ThemeProvider attribute="class">
    {/** @todo: remove */}
    <MediaProvider>
      <TooltipProvider>
        <Provider store={store}>
          <LinkMetadataProvider>{children}</LinkMetadataProvider>
        </Provider>
      </TooltipProvider>
    </MediaProvider>
  </ThemeProvider>
)

const App = ({ Component, pageProps }) => {
  useSupabaseAuth()
  useUpdateUrlWithSpace()
  useWatchSpace()

  const userTheme = useSelector(settingsSelectors.theme)
  const { setTheme: setStitchesTheme } = useTheme()

  useEffect(() => {
    if (typeof window !== 'undefined' && userTheme === Themes.SYSTEM) {
      setStitchesTheme(getSystemTheme())
    }
  }, [userTheme, setStitchesTheme])

  // The browser theme will not change based on our theme.
  // So we have to track it and use the correct favicon.
  const [browserTheme, setBrowserTheme] = useState('light')
  useEffect(() => {
    const media = window.matchMedia('(prefers-color-scheme: dark)')
    setBrowserTheme(media.matches ? 'dark' : 'light')
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta
          name="facebook-domain-verification"
          content="w6vb8rqlmhxki7k9i03s4buno3zbj4"
        />
        <title>Odo - Your Team Odometer</title>
        <link
          rel="icon"
          href={
            browserTheme === 'dark'
              ? '/favicon-odo-dark.png'
              : '/favicon-odo-light.png'
          }
        />
      </Head>
      <ErrorBoundary fallback={<div>An error has occurred</div>} showDialog>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  )
}

const OdoApp = ({ Component, pageProps, language }) => {
  i18nInit(language)
  globalStyles()

  return (
    <Providers>
      <Analytics />
      <App Component={Component} pageProps={pageProps} />
    </Providers>
  )
}

OdoApp.getInitialProps = ({ ctx }) => {
  return { language: getLanguage(ctx) }
}

export default OdoApp
