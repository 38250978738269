import { ThemeMap } from '@sc/theme/stitches.config'

type themeValidKeys = 'sizes' | 'space' | 'zIndices' | 'fontSizes'

const types: themeValidKeys[] = ['sizes', 'space', 'zIndices', 'fontSizes']

const getValueType = (type: ThemeMap[themeValidKeys]) =>
  Object.values(type).reduce((prevValue, themeValue) => {
    const key = themeValue.variable
    const value = themeValue.value

    return {
      ...prevValue,
      [key]: value
    }
  }, {})

/**
 * Gets the breakpoint values mapped from a collection of valid keys
 */
export const getBreakpointValues = (theme: Pick<ThemeMap, themeValidKeys>) => {
  const values = types.reduce((prevValue, type) => {
    return {
      ...prevValue,
      ...getValueType(theme[type])
    }
  }, {})

  return values
}
