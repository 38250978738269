import { authActions, authSelectors } from 'store/auth'
import { fetcher as profileFetcher } from 'services/profile'
import { fetcher as spaceMembershipsFetcher } from 'services/spaces'
import { spacesActions } from 'store/spaces'
import { useEffect } from 'react'
import { useOdoDispatch, useOdoSelector } from 'store'

export const useSupabaseAuth = () => {
  const dispatch = useOdoDispatch()
  const uid = useOdoSelector(authSelectors.uid)

  useEffect(() => {
    const getUserData = async (uid: string) => {
      try {
        dispatch(spacesActions.fetchingMemberships())

        const profilePromise = profileFetcher(uid)
        const membershipPromise = spaceMembershipsFetcher(uid)

        const profile = await profilePromise
        const memberships = await membershipPromise

        dispatch(authActions.profileFetched(profile))
        dispatch(spacesActions.membershipFetched(memberships))
      } catch (e) {
        dispatch(spacesActions.membershipFetchedError())
      }
    }

    if (uid) getUserData(uid)
  }, [uid, dispatch])
}
