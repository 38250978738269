import Script from 'next/script'
import { useEffect } from 'react'

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID
const GADDS_ID = process.env.NEXT_PUBLIC_GADDS_ID
const FP_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FP_MEASUREMENT_ID

export const Analytics = () => {
  return (
    <>
      <GoogleAnalytics />
      <FacebookPixel />
    </>
  )
}

const GoogleAnalytics = () => {
  if (!GA_MEASUREMENT_ID) return null

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', "${GA_MEASUREMENT_ID}");
          gtag('config', "${GADDS_ID}");
        `}
      </Script>
    </>
  )
}

const FacebookPixel = () => {
  if (!FP_MEASUREMENT_ID) return null

  return (
    <>
      <Script id="facebook-pixel" strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FP_MEASUREMENT_ID}'); 
          fbq('track', 'PageView');`}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=1229501747925430&ev=PageView
&noscript=1"
        />
      </noscript>
    </>
  )
}

declare global {
  interface Window {
    dataLayer: any
    fbq: any
  }
}

export const getGtag = (): ((...params: any) => void) => {
  window.dataLayer = window.dataLayer || []
  return function () {
    window.dataLayer.push(arguments)
  }
}

export const trackEvent = (
  event: string,
  parameters?: Record<string, string>
) => {
  const gtag = getGtag()
  gtag('event', event, parameters)
}

export const trackFbqEvent = (event: string) => {
  const fbq = window.fbq
  if (fbq) {
    fbq('track', event)
  }
}

/**
 * Track an event with Google Analytics on mount only
 */
export const useTrackOnce = (
  event: string,
  parameters?: Record<string, string>,
  track: boolean = true
) => {
  useEffect(
    () => {
      if (track) trackEvent(event, parameters)
    },
    // We don't want to track again, even if parameters does change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [track, event]
  )
}
