import { authActions } from 'store/auth'
import { categoriesActions } from 'store/categories'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { OdoStore } from 'store/store'
import { setInitialState } from 'store/actions'
import { spacesActions } from 'store/spaces'
import { Tag } from 'types/tag'

export function tagNaturalSort(a: Tag, b: Tag) {
  return a.name.localeCompare(
    b.name,
    navigator.languages[0] || navigator.language,
    { numeric: true, ignorePunctuation: true }
  )
}

export const tagsAdapter = createEntityAdapter<Tag>({
  sortComparer: tagNaturalSort
})

const initialState = tagsAdapter.getInitialState()

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    tagsRecived: tagsAdapter.setAll,

    createTagRequested: tagsAdapter.addOne,
    createTagSucceeded: tagsAdapter.setOne,
    createTagFailed: tagsAdapter.removeOne,

    updateTagRequested: tagsAdapter.updateOne,
    updateTagSucceeded: tagsAdapter.setOne,
    updateTagFailed: tagsAdapter.setOne
  },
  extraReducers: builder => {
    builder
      .addCase(
        categoriesActions.removeCategorySucceeded,
        (state, { payload }) => {
          const categoryTags = genericSelectors
            .selectAll(state)
            .filter(tag => tag.categoryId === payload)

          const changes = categoryTags.map(tag => ({
            id: tag.id,
            changes: {
              categoryId: null
            }
          }))

          return tagsAdapter.updateMany(state, changes)
        }
      )
      .addCase(setInitialState, (state, action) => ({
        ...(action.payload.tags ? action.payload.tags : state)
      }))
      .addCase(authActions.userSignedOut, () => initialState)
      .addCase(spacesActions.selectedSpaceChanged, () => initialState)
  }
})

export const { actions, reducer } = tagsSlice

export const adapterSelectors = tagsAdapter.getSelectors<OdoStore>(
  state => state.tags
)

export const genericSelectors = tagsAdapter.getSelectors()

export type TagsState = typeof initialState
