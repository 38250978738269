import parser from 'accept-language-parser'
import { NextPageContext } from 'next'
import { languages } from '../i18n/i18n'

export const getLanguage = (ctx: NextPageContext) => {
  if (!ctx.req) return
  const accept = ctx.req.headers['accept-language']
  if (!accept) return 'en'
  const language = parser.pick(languages, accept)
  return language
}
