import { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { categoriesActions } from './categories'
import { Category } from 'types/category'
import { logEntriesActions } from './logEntries'
import { OdoStore } from './store'
import { spacesActions } from './spaces'
import { Tag } from 'types/tag'
import { tagsActions } from './tags'
import { TimeEntry } from 'types/timeEntry'

export const safeStaleCategoriesTagsRecieved = (
  categories: Array<Category>,
  tags: Array<Tag>,
  spaceId: string
): ThunkAction<boolean, OdoStore, void, AnyAction> => {
  return (dispatch, getStore) => {
    const {
      spaces: { currentSpaceId }
    } = getStore()

    if (currentSpaceId !== spaceId) {
      // It is important to return the result of the thunk action. Returning undefined tells SWR that
      // the data fetch failed, so it will be attempted again.
      return undefined
    }

    batch(() => {
      dispatch(categoriesActions.categoriesReceived(categories))
      dispatch(tagsActions.tagsRecived(tags))
    })

    return true
  }
}

export const safeStaleLogEntriesRecieved = (
  payload: {
    entries: Array<TimeEntry>
    nextCursor: string | null
  },
  spaceId: string,
  source: 'swr' | 'writeError' | 'nextPage'
): ThunkAction<boolean, OdoStore, void, AnyAction> => {
  return (dispatch, getStore) => {
    const {
      spaces: { currentSpaceId }
    } = getStore()

    if (currentSpaceId !== spaceId) {
      // It is important to return the result of the thunk action. Returning undefined tells SWR that
      // the data fetch failed, so it will be attempted again.
      return undefined
    }

    let action: any

    if (source === 'swr') {
      action = logEntriesActions.entriesReceived(payload)
    } else if (source === 'writeError') {
      action = logEntriesActions.entryWriteError(payload)
    } else {
      action = logEntriesActions.entriesPageReceived(payload)
    }

    batch(() => {
      dispatch(action)
      dispatch(logEntriesActions.fetchSucceeded())
    })

    return true
  }
}

export const spaceChangeRequested = (
  spaceId: string | undefined | null
): ThunkAction<void, OdoStore, void, AnyAction> => {
  return (dispatch, getStore) => {
    const {
      spaces: { currentSpaceId }
    } = getStore()

    if (spaceId && spaceId !== currentSpaceId) {
      dispatch(spacesActions.selectedSpaceChanged(spaceId))
    }
  }
}
