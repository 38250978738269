import { createAction } from '@reduxjs/toolkit'
import { Themes } from 'types/themes'
import { withPayloadType } from '../withPayloadType'

const ACTION_PREFIX = 'settings/'

export const themeChanged = createAction(
  `${ACTION_PREFIX}themeChanged`,
  withPayloadType<Themes>()
)
