export const hexToRGBA = (color: string, alpha = 1) => {
  const colorSplitted = color.match(/\w\w/g)

  if (!colorSplitted) {
    return '#FFF'
  }

  const [r, g, b] = colorSplitted.map((x) => parseInt(x, 16))

  return `rgba(${r},${g},${b},${alpha})`
}
