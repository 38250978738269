import Stripe from 'stripe'

export enum ErrorName {
  DomainLogic = 'domainLogic',
  Unsubscribed = 'unsubscribed',
  UserAlreadyJoined = 'userAlreadyJoined',
  BadRequest = 'badRequest',
  Unauthorized = 'unauthorized',
  NotFound = 'notFound',
  ServerError = 'serverError',
  Unknown = 'unknown'
}

/**
 * @param name indicates the type of error.
 * @param message used for debugging.
 */
export class OdoError extends Error {
  readonly __OdoError = true
  readonly name: ErrorName

  constructor(name: ErrorName, message: string = '') {
    super(message)
    this.name = name
  }
}

export class ApiError extends OdoError {
  readonly status: number

  constructor(name: ErrorName, status: number, message?: string) {
    super(name, message)
    this.status = status
  }

  toJSON() {
    return {
      name: this.name,
      status: this.status,
      message: this.message
    }
  }
}

export class BadRequestError extends ApiError {
  constructor(message?: string) {
    super(ErrorName.BadRequest, 400, message)
  }
}

export class UnauthorizedError extends ApiError {
  constructor(message?: string) {
    super(ErrorName.Unauthorized, 401, message)
  }
}

export class NotFoundError extends ApiError {
  constructor(message?: string) {
    super(ErrorName.NotFound, 404, message)
  }
}

export class ConflictError extends ApiError {
  constructor(name: ErrorName, message?: string) {
    super(name, 409, message)
  }
}

export class ServerError extends ApiError {
  constructor(message?: string) {
    super(ErrorName.ServerError, 500, message)
  }
}

// Utils
export function isApiError(error: unknown): error is ApiError {
  return typeof error === 'object' && error !== null && 'status' in error
}

export function isStripeError(error: unknown): error is Stripe.StripeError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'type' in error &&
    'raw' in error
  )
}
