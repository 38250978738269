import * as fuzzySelectors from './fuzzySelectors'
import * as selectors from './tagsSelectors'

import { adapterSelectors } from './tagsSlice'

export {
  genericSelectors as tagsGenericSelectors,
  actions as tagsActions,
  reducer as tagsReducer
} from './tagsSlice'

export const tagsSelectors = {
  ...adapterSelectors,
  ...selectors,
  ...fuzzySelectors
}
