import { Profile } from 'types/profile'
import { signOut } from './auth'
import { toOdoProfile } from './mappers'
import { supabase } from './supabase'

export const fetcher = async (id: string): Promise<Profile> => {
  const { data } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', id)
    .throwOnError()

  return data[0] ? toOdoProfile(data[0]) : null
}

export const updater = async (id: string, changes: Pick<Profile, 'name'>) => {
  const { data } = await supabase
    .from('profiles')
    .update(changes)
    .eq('id', id)
    .throwOnError()
    .select()

  return data[0] ? toOdoProfile(data[0]) : null
}

export const emailUpdater = async (email: string) => {
  const { error } = await supabase.auth.updateUser({ email })

  if (error) throw error

  signOut()
}
