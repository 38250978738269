export type Screens = 'log' | 'search'

export type TagList = {
  type: 'grid' | 'list'
  initialMessageDismissed?: boolean
}

export type TagListByScreens = {
  [K in Screens]: TagList
}

export const LOCAL_STORAGE_TAG_LIST = 'odo-tag-list'
