import { createSelector } from 'reselect'
import { differenceInSeconds, format, startOfDay } from 'date-fns'
import { EntriesGroupedByDay } from 'types/timeEntry'
import { OdoStore } from 'store'
import { sortComparer } from 'store/timeEntries'
import { timeEntriesGenericSelectors } from 'store/timeEntries'
import { createDeepEqualSelector } from 'store/createDeepEqualSelector'

const timeEntriesSlice = (state: OdoStore) => state.timeEntries
const logEntriesSlice = (state: OdoStore) => state.logEntries

const _logEntries = createSelector(
  logEntriesSlice,
  timeEntriesSlice,
  ({ ids }, entries) => {
    if (ids) {
      return ids
        .map(id => timeEntriesGenericSelectors.selectById(entries, id))
        .filter(entry => entry && !entry.sample)
        .sort(sortComparer)
    }

    return []
  }
)

export const isLoading = createSelector(logEntriesSlice, log => log.loading)

export const endReached = createSelector(logEntriesSlice, log => {
  return Array.isArray(log.ids) && log.nextCursor === null
})

export const queryContext = createSelector(logEntriesSlice, log => ({
  isLoading: log.loading,
  currentSize: log.ids ? log.ids.length : 0,
  nextCursor: log.nextCursor
}))

// This selector will prevent the groupByDay selector to be recalculated when
// the description or any other attribute changes, which are props we don't care about.
const _trimmedLogEntries = createDeepEqualSelector(_logEntries, entries => {
  return entries.map(entry => {
    return {
      id: entry.id,
      dateStart: entry.dateStart,
      dateEnd: entry.dateEnd,
      sample: entry.sample
    }
  })
})

export const groupedByDay = createSelector(_trimmedLogEntries, entries => {
  return entries.reduce<EntriesGroupedByDay>(function (map, entry) {
    if (!entry.dateEnd) return map

    const date = format(entry.dateStart, 'yyyy-MM-dd')
    const seconds = differenceInSeconds(entry.dateEnd, entry.dateStart)

    if (map[date]) {
      map[date].entries.push(entry.id)
      map[date].totalSeconds += seconds
    } else {
      map[date] = {
        entries: [entry.id],
        date: startOfDay(entry.dateStart),
        totalSeconds: seconds
      }
    }

    return map
  }, {})
})

export const runningEntry = createSelector(_logEntries, entries => {
  return entries.find(entry => !entry.dateEnd)
})

export const sampleLogEntryIds = createSelector(
  logEntriesSlice,
  timeEntriesSlice,
  ({ ids }, entries) => {
    if (ids) {
      return ids
        .map(id => timeEntriesGenericSelectors.selectById(entries, id))
        .filter(entry => entry && entry.sample)
        .sort(sortComparer)
        .map(({ id }) => id)
    }

    return []
  }
)
