import * as dateFns from 'date-fns'

export const isToday = dateFns.isToday

export const isSingleDay = (start: Date, end: Date) => {
  return (
    dateFns.startOfDay(start).valueOf() === start.valueOf() &&
    dateFns.endOfDay(start).valueOf() === end.valueOf()
  )
}

export const isWholeWeek = (start: Date, end: Date) => {
  return (
    dateFns.startOfWeek(start, { weekStartsOn: 1 }).valueOf() ===
      start.valueOf() &&
    dateFns.endOfWeek(start, { weekStartsOn: 1 }).valueOf() === end.valueOf()
  )
}

export const isThisWeek = (start: Date, end: Date, opts = { offset: 0 }) => {
  const anchor =
    opts.offset > 0 ? dateFns.subWeeks(Date.now(), opts.offset) : Date.now()

  return (
    dateFns.startOfWeek(anchor, { weekStartsOn: 1 }).valueOf() ===
      start.valueOf() &&
    dateFns.endOfWeek(anchor, { weekStartsOn: 1 }).valueOf() === end.valueOf()
  )
}

export const isWholeMonth = (start: Date, end: Date) => {
  return (
    dateFns.startOfMonth(start).valueOf() === start.valueOf() &&
    dateFns.endOfMonth(start).valueOf() === end.valueOf()
  )
}

export const isThisMonth = (start: Date, end: Date, opts = { offset: 0 }) => {
  const anchor =
    opts.offset > 0 ? dateFns.subMonths(Date.now(), opts.offset) : Date.now()

  return (
    dateFns.startOfMonth(anchor).valueOf() === start.valueOf() &&
    dateFns.endOfMonth(anchor).valueOf() === end.valueOf()
  )
}

export const isWholeYear = (start: Date, end: Date) => {
  return (
    dateFns.startOfYear(start).valueOf() === start.valueOf() &&
    dateFns.endOfYear(start).valueOf() === end.valueOf()
  )
}

export const isThisYear = (start: Date, end: Date, opts = { offset: 0 }) => {
  const anchor =
    opts.offset > 0 ? dateFns.subYears(Date.now(), opts.offset) : Date.now()

  return (
    dateFns.startOfYear(anchor).valueOf() === start.valueOf() &&
    dateFns.endOfYear(anchor).valueOf() === end.valueOf()
  )
}
