import * as sentry from '@sentry/react'
import alerts from 'joist/modules/alerts'
import { authReducer } from './auth'
import { categoriesReducer } from './categories'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { logEntriesReducer } from './logEntries'
import { searchReducer } from './search'
import { settingsReducer } from './settings'
import { spaceReducer } from './spaces'
import { tagListReducer } from './tagList'
import { tagsReducer } from './tags'
import { timeEntriesReducer } from './timeEntries'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const sentryReduxEnhancer = sentry.createReduxEnhancer()

const initialState = {}

export const store = configureStore({
  reducer: {
    alerts: alerts.reducer,
    search: searchReducer,
    spaces: spaceReducer,
    auth: authReducer,
    timeEntries: timeEntriesReducer,
    logEntries: logEntriesReducer,
    categories: categoriesReducer,
    tags: tagsReducer,
    tagList: tagListReducer,
    settings: settingsReducer
  },
  preloadedState: initialState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
  // devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer]
})

export type OdoStore = ReturnType<typeof store.getState>

export const useOdoSelector: TypedUseSelectorHook<OdoStore> = useSelector
export const useOdoDispatch = () => useDispatch<typeof store.dispatch>()
