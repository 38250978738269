import {
  LOCAL_STORAGE_TAG_LIST,
  TagList,
  Screens,
  TagListByScreens
} from 'types/tagList'
import { store } from 'store/store'

const getTagList = (): TagListByScreens | null => {
  const tagList =
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAG_LIST) || '')
      : null

  return tagList
}

export const getTagListFromLocalStorage = () => {
  try {
    const tagList = getTagList()

    return tagList
  } catch {
    return null
  }
}

export const setTagListToLocalStorage = <K extends keyof TagList>(
  key: K,
  value: TagList[K],
  screen: Screens
) => {
  if (typeof window !== 'undefined') {
    const tagListByScreens = store.getState().tagList

    const newTagList = {
      ...tagListByScreens,
      [screen]: { ...tagListByScreens[screen], [key]: value }
    }

    localStorage.setItem(LOCAL_STORAGE_TAG_LIST, JSON.stringify(newTagList))
  }
}
