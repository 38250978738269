import * as actions from './searchActions'
import * as authActions from 'store/auth/authActions'
import * as dateFns from 'date-fns'
import { createReducer } from '@reduxjs/toolkit'
import { guessPresetOfRange } from 'utils/searchPresets'
import { initialState, SearchState } from './searchState'
import { setInitialState } from 'store/actions'
import { spacesActions } from 'store/spaces'
import { WritableDraft } from 'immer/dist/internal'

const offsetDay = (state: WritableDraft<SearchState>, days: number) => {
  const temp = dateFns.addDays(state.dateStart, days)
  state.dateStart = dateFns.startOfDay(temp)
  state.dateEnd = dateFns.endOfDay(temp)
}

const offsetWeek = (state: WritableDraft<SearchState>, weeks: number) => {
  state.dateStart = dateFns.startOfWeek(
    dateFns.addWeeks(state.dateStart, weeks),
    {
      weekStartsOn: 1
    }
  )
  state.dateEnd = dateFns.endOfWeek(dateFns.addWeeks(state.dateEnd, weeks), {
    weekStartsOn: 1
  })
}

const offsetMonth = (state: WritableDraft<SearchState>, months: number) => {
  state.dateStart = dateFns.startOfMonth(
    dateFns.addMonths(state.dateStart, months)
  )
  state.dateEnd = dateFns.endOfMonth(dateFns.addMonths(state.dateEnd, months))
}

const offsetYear = (state: WritableDraft<SearchState>, years: number) => {
  state.dateStart = dateFns.startOfYear(
    dateFns.addYears(state.dateStart, years)
  )
  state.dateEnd = dateFns.endOfYear(dateFns.addYears(state.dateEnd, years))
}

export const searchReducer = createReducer(initialState, builder =>
  builder
    .addCase(setInitialState, (_, { payload }) => {
      return payload.search ?? initialState
    })
    .addCase(authActions.userSignedOut, () => initialState)
    .addCase(spacesActions.selectedSpaceChanged, () => initialState)
    .addCase(actions.tagsQueryUpdated, (state, { payload }) => {
      state.query = payload.query
      state.tags = payload.tags
    })
    .addCase(actions.personAdded, (state, { payload }) => {
      const people = new Set(state.people)
      people.add(payload)
      state.people = Array.from(people)
    })
    .addCase(actions.personRemoved, (state, { payload }) => {
      state.people = state.people.filter(id => id !== payload)
    })
    .addCase(actions.peopleQueryUpdated, (state, { payload }) => {
      state.people = payload
    })
    .addCase(actions.rangeUpdated, (state, { payload }) => {
      state.dateStart = dateFns.startOfDay(payload.dateStart)
      state.dateEnd = dateFns.endOfDay(payload.dateEnd)
      state.preset =
        payload.preset ?? guessPresetOfRange(payload.dateStart, payload.dateEnd)
    })
    .addCase(actions.rangeOffsetForward, state => {
      switch (state.preset) {
        case 'day':
          offsetDay(state, 1)
          break

        case 'week':
          offsetWeek(state, 1)
          break

        case 'month':
          offsetMonth(state, 1)
          break

        case 'year':
          offsetYear(state, 1)
          break

        default:
          const diff = dateFns.differenceInCalendarDays(
            state.dateEnd,
            state.dateStart
          )

          state.dateStart = dateFns.startOfDay(
            dateFns.addDays(state.dateEnd, 1)
          )
          state.dateEnd = dateFns.endOfDay(
            dateFns.addDays(state.dateEnd, diff + 1)
          )
          break
      }
    })
    .addCase(actions.rangeOffsetBackward, state => {
      switch (state.preset) {
        case 'day':
          offsetDay(state, -1)
          break

        case 'week':
          offsetWeek(state, -1)
          break

        case 'month':
          offsetMonth(state, -1)
          break

        case 'year':
          offsetYear(state, -1)
          break

        default:
          const diff = dateFns.differenceInCalendarDays(
            state.dateEnd,
            state.dateStart
          )

          state.dateEnd = dateFns.endOfDay(dateFns.subDays(state.dateStart, 1))
          state.dateStart = dateFns.startOfDay(
            dateFns.subDays(state.dateStart, diff + 1)
          )
          break
      }
    })
)
