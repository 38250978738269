import { supabase } from './supabase'
import { store } from 'store'
import { authActions } from 'store/auth'
import { Profile } from 'types/profile'
import { AuthSessionMissingError } from '@supabase/supabase-js'

export const signIn = async (email: string, password: string) => {
  const {
    error,
    data: { session }
  } = await supabase.auth.signInWithPassword({ email, password })

  if (error) throw error

  store.dispatch(authActions.userSignedIn(session))

  return { session }
}

export const signUp = async (email: string, password: string, name: string) => {
  const {
    data: { session },
    error
  } = await supabase.auth.signUp({
    email,
    password,
    // The profile will be created by a database trigger.
    options: { data: { initial_name: name } }
  })

  if (error) throw error

  const profile: Profile = { id: session.user.id, name, email }

  store.dispatch(authActions.userSignedUp(profile))

  return { session, profile }
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()

  if (error) throw error
}

export const sendPasswordResetEmail = async () => {
  const {
    data: { session }
  } = await supabase.auth.getSession()

  if (!session) throw new AuthSessionMissingError()

  const { error } = await supabase.auth.resetPasswordForEmail(
    session.user.email,
    {
      redirectTo: window.location.origin + '/auth/password-reset'
    }
  )

  if (error) throw error

  await signOut()
}

export const anonForgotPassword = async (email: string) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: window.location.origin + '/auth/password-reset'
  })

  if (error) throw error
}

export const resetPassword = async (password: string) => {
  const { error } = await supabase.auth.updateUser({
    password
  })

  if (error) throw error
}
