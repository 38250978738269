var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"34a40d3efc3a20fd8a51a537188553365b0331e7"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ENABLE_SENTRY =
  process.env.ENABLE_SENTRY || process.env.NEXT_PUBLIC_ENABLE_SENTRY

if (ENABLE_SENTRY) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://c91a488546304eef9827a28518a503e4@o524261.ingest.sentry.io/5636807',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
    normalizeDepth: 7,
    // For now we want the dialog to be opened right after an error happens
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: {
            name: event.contexts['redux.state'].auth.profile?.name || '',
            email: event.contexts['redux.state'].auth.profile?.email || ''
          }
        })
      }
      return event
    }
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
