import { createReducer } from '@reduxjs/toolkit'
import * as actions from './settingsActions'
import { SettingsState } from '.'
import { getThemeFromLocalStorage } from 'utils/theme'
import { setInitialState } from 'store/actions'

const getInitialStateFromLocalStorage = (): SettingsState => {
  return {
    theme: getThemeFromLocalStorage()
  }
}
export const settingsReducer = createReducer(
  getInitialStateFromLocalStorage,
  builder =>
    builder
      .addCase(actions.themeChanged, (state, action) => ({
        ...state,
        theme: action.payload
      }))
      .addCase(setInitialState, (state, action) => ({
        ...(action.payload.settings ? action.payload.settings : state)
      }))
)
