import mudder from 'mudder'
import { captureException } from 'sentry'

type Item = {
  id: string
  rank: string
}

type Collection = Array<Item>

const dictionary = mudder.base62

const lexorank = (start = '', end = '') => {
  try {
    return dictionary.mudder(start, end, 1)[0]
  } catch (e) {
    captureException(e, {
      extra: {
        start,
        end
      }
    })
    throw e
  }
}

export function sortByRankAsc<T extends Item>(a: T, b: T) {
  return a.rank <= b.rank ? -1 : 1
}

export function sortByRankDesc<T extends Item>(a: T, b: T) {
  return a.rank <= b.rank ? 1 : -1
}

export function getEndOfCollectionRank<T extends Collection>(collection: T) {
  let start = ''

  if (collection.length > 0) {
    start = collection[collection.length - 1].rank
  }

  return lexorank(start)
}

// This works the same way as Array.splice(). The element present in targetPos will be shifted to targetPos + 1.
export function moveItemToPosition<T extends Collection>(
  collection: T,
  targetPos: number
) {
  const start = collection[targetPos - 1] ? collection[targetPos - 1].rank : ''
  const end = collection[targetPos] ? collection[targetPos].rank : ''

  return lexorank(start, end)
}

export function moveItemOnePosition<T extends Collection>(
  collection: T,
  currentPos: number,
  dir: 'up' | 'down'
): string | undefined {
  if (dir === 'up') {
    // if the start el is undefined, it means that the item we want to sort is already at the end of the array. Thus, do not perform any action
    const start = collection[currentPos + 1]
      ? collection[currentPos + 1].rank
      : ''
    const end = collection[currentPos + 2]
      ? collection[currentPos + 2].rank
      : ''

    return start ? lexorank(start, end) : undefined
  }

  // if the end el is undefined, it means that the item we want to sort is already at the end of the array. Thus, do not perform any action
  const end = collection[currentPos - 1] ? collection[currentPos - 1].rank : ''
  const start = collection[currentPos - 2]
    ? collection[currentPos - 2].rank
    : ''

  return end ? lexorank(start, end) : undefined
}

export function insertItemToTheStart<T extends Collection>(collection: T) {
  const end = collection[0] ? collection[0].rank : ''
  return lexorank('', end)
}

export function insertItemToTheEnd<T extends Collection>(collection: T) {
  const length = collection.length
  const start = length > 0 ? collection[length - 1].rank : ''

  return lexorank(start, '')
}

export function rebalance<T>(collection: Array<T>) {
  const ranks = dictionary.mudder(collection.length)
  return collection.map((item, i) => ({ ...item, rank: ranks[i] }))
}
