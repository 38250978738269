import { defaultGlobalStyles } from '@sc/theme/defaultGlobalStyles'
import { getDarkModeStyle } from '@sc/theme/getDarkModeStyle'
import { light, dark } from '@sc/theme/colors'
import { large, small } from '@sc/theme/metrics'
import { utils } from '@sc/theme/utils'
import { createStitches } from '@stitches/react'

const { styled, theme, createTheme, keyframes, getCssText, globalCss, css } =
  createStitches({
    media: {
      xs: '(min-width: 480px)',
      sm: '(min-width: 640px)',
      md: '(min-width: 1024px)'
    },

    theme: {
      colors: {
        ...light,
        background: '#F9F9F9',
        panel: '#FFFFFF',
        divider: '#EEEEEE',
        outline: '#C9C9C9',
        overlay: '#E5E5E599',
        danger: '#EF19191A',
        button: '#F1F1F1',
        highContrast: '#414141',
        gradientPanel:
          'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 100%)',

        text: '#2E2E2E',
        textInverted: '#FFFFFF',
        textLowContrast: '#2E2E2EB2',
        textDanger: '#EF1919',

        black: '#2E2E2E',
        white: '#FFFFFF'
      },

      sizes: {
        ...small.sizes,
        navbarHeight: '5rem',
        barHeight: '4rem',
        controlHeight: '3rem',
        icon: '2rem'
      },

      space: {
        ...small.space,
        pt: '1px',
        1: '0.5rem', // 5px
        2: '1rem', // 10px
        3: '1.5rem', // 15px
        4: '2rem', // 20px
        5: '2.5rem', // 25px
        6: '3.0rem', // 30px
        7: '3.5rem', // 35px
        8: '4rem', // 40px

        editorWrap: '2px',
        controlPadding: '1rem',

        // This calc is the navbar height + margin with the bottom viewport + margin with page content
        bottomNavMargin: 'calc($sizes$navbarHeight + $2 + $6)'
      },

      radii: {
        control: '2px',
        tag: '100px',
        panel: '10px',
        full: '100%'
      },

      fontSizes: {
        small: '1.3rem', // 13px,
        base: '1.5rem', // 15px,
        large: '2.3rem', // 23px

        // mobile
        mlarge: '1.9rem' // 19px
      },

      letterSpacings: {
        tight: '-0.005em', // -0.005%
        base: '-0.01em' // -0.01%
      },

      lineHeights: {
        none: 1,
        editor: '32px',
        tight: '1.6rem', // 16px
        normal: '1.9rem', // 19px
        relaxed: '2.2rem', // 22px
        loose: '2.7rem' // 27px
      },

      fontWeights: {
        normal: 400,
        semibold: 500,
        bold: 700
      },

      shadows: {
        panel: '0px 0px 6px 1px rgba(0, 0, 0, 0.1)',
        nav: '0px 0px 8px rgba(0, 0, 0, 0.2)'
      }
    },
    utils
  })

const darkTheme = createTheme('dark', {
  colors: {
    ...dark,

    background: '#2E2E2E',
    panel: '#414141',
    divider: '#4B4B4B',
    outline: '#6B6B6B',
    overlay: '#2E2E2E99',
    danger: '#EF19191A',
    button: '#515151',
    highContrast: '#FFFFFF',
    gradientPanel:
      'linear-gradient(0deg, rgba(65,65,65,0) 0%, rgba(65,65,65,1) 5%, rgba(65,65,65,1) 100%)',

    text: '#FFFFFF',
    textInverted: '#2E2E2E',
    textLowContrast: '#FFFFFFB2',
    textDanger: '#EF1919',

    black: '#2E2E2E',
    white: '#FFFFFF'
  }
})

// You have to call this for the theme CSS to be output
darkTheme.toString()

export const desktop = createTheme({
  sizes: {
    ...large.sizes
  }
})

// Apply custom styles
const globalStyles = globalCss({
  ...defaultGlobalStyles,

  ...getDarkModeStyle(darkTheme),
  '.disable-overflow': {
    overflow: 'hidden !important'
  }
})

export {
  theme,
  keyframes,
  styled,
  darkTheme,
  css,
  getCssText,
  globalStyles,
  globalCss
}
