import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authSelectors } from 'store/auth'
import { spacesSelectors } from 'store/spaces'
import { remember } from 'services/spaces'

export const useWatchSpace = () => {
  const isAuthenticated = useSelector(authSelectors.authenticated)
  const currentSpaceId = useSelector(spacesSelectors.currentSpaceId)

  useEffect(() => {
    if (isAuthenticated && currentSpaceId) remember(currentSpaceId)
  }, [isAuthenticated, currentSpaceId])
}
