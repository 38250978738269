import React, { ElementRef } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { CSS } from '@stitches/react'
import { styled } from '../theme/stitches.config'

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: '$S',
  color: '$fgHigh',
  paddingLeft: '$paddingUIXL',
  paddingRight: '$paddingUIXL',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '$levelTwo',
  borderWidth: '$default',
  borderStyle: 'solid',
  borderColor: '$strokeTertiary',
  height: '$controlHeight',
  fontSize: '$base',
  backgroundColor: '$surfaceFg'
})

export type TooltipProps = TooltipPrimitive.TooltipContentProps & {
  /**
   * Value shown by the Tooltip
   */
  content: string
  /**
   * Disables the Tooltip
   */
  disabled?: boolean
  css?: CSS
}

type TooltipRef = ElementRef<typeof TooltipPrimitive.Content>

export const StyledTooltip = React.forwardRef<TooltipRef, TooltipProps>(
  ({ children, disabled = false, content, ...props }, ref) => {
    if (disabled) {
      return <>{children}</>
    }

    return (
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {content?.length > 0 && (
          <TooltipPrimitive.Portal>
            <StyledContent side="bottom" ref={ref} {...props}>
              {content}
            </StyledContent>
          </TooltipPrimitive.Portal>
        )}
      </TooltipPrimitive.Root>
    )
  }
)

export const TooltipProvider = TooltipPrimitive.Provider
export const TooltipContent = StyledContent
export const Tooltip = StyledTooltip
