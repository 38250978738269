import Cookies from 'js-cookie'
import { apiCall } from './apiCall'
import { browserTz } from 'utils'
import { CreateSpace } from 'types/api'
import { LAST_SPACE_COOKIE, ODO_COOKIE_OPTIONS } from 'utils/constants'
import { supabase } from './supabase'
import { SupabaseSpace } from 'types/space'
import { toOdoSpace, toOdoSpaceMembership } from './mappers'

// Store the last visited space inside a cookie, so the backend get its and can redirect accordingly.
export const getLastVisited = () => {
  return Cookies.get(LAST_SPACE_COOKIE)
}

export const remember = (spaceId: string) => {
  Cookies.set(LAST_SPACE_COOKIE, spaceId, {
    ...ODO_COOKIE_OPTIONS,
    expires: 365
  })
}

export const forget = () => {
  Cookies.remove(LAST_SPACE_COOKIE)
}

// Space memberships fetcher
export const fetcher = async (uid: string) => {
  const { data } = await supabase
    .from('memberships')
    .select(`id, access_level, user_id, space:space_id(id, name)`)
    .eq('user_id', uid)

  return data ? data.map(toOdoSpaceMembership) : []
}

export const creator = async (name: string) => {
  const {
    data: { space, membership }
  } = await apiCall<CreateSpace>('create-space', {
    name,
    timezone: browserTz()
  })

  return {
    space: toOdoSpace(space),
    spaceMembership: toOdoSpaceMembership({ ...membership, space })
  }
}

export const updater = async (
  id: string,
  changes: Pick<SupabaseSpace, 'name'>
) => {
  return supabase.from('spaces').update(changes).eq('id', id).throwOnError()
}
