const padding0 = '0'
const padding1 = '0.2rem'
const padding2 = '0.4rem'
const padding3 = '0.6rem'
const padding4 = '0.8rem'
const padding5 = '1.2rem'
const padding6 = '1.6rem'
const padding7 = '2.4rem'

export const globalMetrics = {
  space: {
    padding0,
    padding1,
    padding2,
    padding3,
    padding4,
    padding5,
    padding6,
    padding7
  },
  radii: {
    0: 0,
    S: '4px',
    M: '8px',
    L: '25px',
    xL: '200px'
  },
  sizes: {
    gridOverflow: '480px'
  }
}

const spacerYMasterLarge = 1.6
const spacerYMasterScaleLarge = 2
const spacerYXS = spacerYMasterLarge
const spacerYS = spacerYMasterLarge * spacerYMasterScaleLarge
const spacerYM = spacerYS * spacerYMasterScaleLarge
const spacerYL = spacerYM * spacerYMasterScaleLarge
const spacerYXL = spacerYL * spacerYMasterScaleLarge
const spacerY2XL = spacerYXL * spacerYMasterScaleLarge
const spacerY3XL = spacerY2XL * spacerYMasterScaleLarge

const small = {
  space: {
    paddingUIS: padding2,
    paddingUIM: padding3,
    paddingUIL: padding4,
    paddingUIXL: padding5,
    paddingAccordionLeft: padding2,
    paddingUITabbed: padding1,
    paddingContainerLevelOne: padding4,
    paddingContainerLevelTwo: padding6,
    paddingContainerBubbleY: padding5,
    paddingContainerBubbleX: padding6,
    paddingBetweenNone: padding0,
    paddingBetweenSmall: '0.4rem',
    paddingBetweenMed: '0.6rem',
    paddingBetweenLarge: '0.8rem',
    paddingBetweenXL: '1.2rem',
    paddingBetween2XL: '2.4rem'
  },
  sizes: {
    spacerYMaster: `${spacerYMasterLarge}rem`,
    spacerYMasterScale: `${spacerYMasterScaleLarge}rem`,
    spacerYXS: `${spacerYXS}rem`,
    spacerYS: `${spacerYS}rem`,
    spacerYM: `${spacerYM}rem`,
    spacerYL: `${spacerYL}rem`,
    spacerYXL: `${spacerYXL}rem`,
    spacerY2XL: `${spacerY2XL}rem`,
    spacerY3XL: `${spacerY3XL}`,
    articleWidthXXS: '27.9rem',
    articleWidthXS: '31.4rem',
    articleWidthS: '31.4rem',
    articleWidthM: '34.3rem',
    articleWidthL: '35.9rem',
    divider: '0.1rem',
    iconSmall: '1rem',
    iconBase: '1.2rem',
    iconBoxSmall: '1.9rem',
    infoMinHeight: '4.8rem',
    controlHeight: '3rem'
  }
}

const large = {
  space: {
    paddingUIS: padding2,
    paddingUIM: padding3,
    paddingUIL: padding4,
    paddingUIXL: padding5,
    paddingUITabbed: padding2,
    paddingAccordionLeft: padding3,
    paddingContainerLevelOne: padding5,
    paddingContainerLevelTwo: padding7,
    paddingContainerBubbleY: padding6,
    paddingContainerBubbleX: padding7,
    paddingBetweenNone: padding0,
    paddingBetweenSmall: padding2,
    paddingBetweenMed: padding3,
    paddingBetweenLarge: padding4,
    paddingBetweenXL: padding5,
    paddingBetween2XL: '2.4rem'
  },
  sizes: {
    spacerYMaster: `${spacerYMasterLarge}rem`,
    spacerYMasterScale: `${spacerYMasterScaleLarge}rem`,
    spacerYXS: `${spacerYXS}rem`,
    spacerYS: `${spacerYS}rem`,
    spacerYM: `${spacerYM}rem`,
    spacerYL: `${spacerYL}rem`,
    spacerYXL: `${spacerYXL}rem`,
    spacerY2XL: `${spacerY2XL}rem`,
    spacerY3XL: `${spacerY3XL}`,
    articleWidthXXS: '43.5rem',
    articleWidthXS: '58.2rem',
    articleWidthS: '65.6rem',
    articleWidthM: '73rem',
    articleWidthL: '87.8rem',
    containerWidthViewPort: '168rem',
    containerWidthArticle: '35.9rem',
    divider: '0.14rem',
    iconSmall: '1rem',
    iconBase: '1.4rem',
    iconBoxSmall: '2.4rem',
    infoMinHeight: '6rem',
    controlHeight: '3.6rem'
  }
}

export { small, large }
