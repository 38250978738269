import { SpaceMembership } from 'types/membership'
import { Space } from 'types/space'

export type SpaceState = {
  currentSpaceId: Space['id']
  memberships: Array<SpaceMembership>
  loading: boolean
}

export const initialState: SpaceState = {
  currentSpaceId: null,
  memberships: [],
  loading: true
}
