import { addDays, format } from 'date-fns'

export const isEmailValid = email => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const isValidHexColor = color => {
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return hexColorRegex.test(color)
}

// This doesn't trim text, so the user can write spaces.
export const normalizeName = text => {
  return text
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers, dashes and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export const slugify = value => {
  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers, dashes and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export const normalizeColor = text => {
  if (text === '') return '#'
  if (text.length > 7) return text.substr(0, 7)
  return text.replace(/\s/g, '')
}

export const getDateObjectFromDateAndTime = (date, time) => {
  return new Date(
    format(date, 'yyyy'),
    format(date, 'M') - 1,
    format(date, 'd'),
    time.split(':')[0],
    time.split(':')[1]
  )
}

export const getTimeRangeDateObjects = (selectedDay, startTime, endTime) => {
  const dateTimeStart = getDateObjectFromDateAndTime(selectedDay, startTime)
  let dateTimeEnd = getDateObjectFromDateAndTime(selectedDay, endTime)

  // if end time is earlier than start time, we need to add one day as the time is for the next day
  if (new Date(dateTimeEnd) < new Date(dateTimeStart)) {
    dateTimeEnd = addDays(dateTimeEnd, 1)
  }

  return [dateTimeStart, dateTimeEnd]
}

export const parseCollectionToTextQuery = values => {
  const valuesParsed = values.map(value => {
    if (value === '&' || value === '/' || value === '%') {
      return '&'.concat(value)
    }
    return value
  })
  return valuesParsed.join('')
}

export const parseTextToTags = value => {
  const tagsIdReg = new RegExp(`#([\\w-_]{36})`, 'g')
  const tags = value.match(tagsIdReg)
  return tags || []
}

export const humanizeDate = date => date.valueOf()

const dateOnlyFormat = 'yyyy-MM-dd'

// New function
export const groupEntriesByDay = timeEntries => {
  if (!timeEntries || timeEntries.length === 0) return {}

  return timeEntries.reduce((acc, entry) => {
    const date = format(entry.dateStart, dateOnlyFormat)

    if (!acc[date]) {
      acc[date] = [entry]
    } else {
      acc[date].push(entry)
    }

    return acc
  }, {})
}

export const editCollection = (oldCollection, value, index) => {
  return [
    ...oldCollection.slice(0, index),
    value,
    ...oldCollection.slice(index + 1)
  ]
}

export const deleteItemFromCollection = (oldCollection, start, end) => {
  return [...oldCollection.slice(0, start), ...oldCollection.slice(end + 1)]
}

export const toDate = ms => new Date(parseInt(ms))

export const pairwise = list => {
  if (list.length < 2) {
    return []
  }
  const first = list[0]
  const [second, ...rest] = list.slice(1)
  const pairs = [[first, second]]

  return pairs.concat(pairwise([second, ...rest]))
}

export const copyInputToClipboard = inputId => {
  const input = document.getElementById(inputId)
  input.select()
  document.execCommand('copy')
}

export const capitalize = value => {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const browserTz = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    return null
  }
}

export const voidCallback = () => {}
