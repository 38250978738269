import { hexToRGBA } from '@sc/utils/hexToRGBA'

const team500 = '#CFBA02'
const team600 = '#BDAA00'
const market500 = '#4A76CF'
const market600 = '#305DB5'
const commercial500 = '#A366AD'
const commercial600 = '#91539B'
const product600 = '#FE5B27'

const grey100 = '#F9F9F9'
const grey200 = '#EDEDED'
const grey500 = '#C9C9C9'
const grey600 = '#6B6B6B'
const grey900 = '#373737'
const grey1000 = '#2E2E2E'

const whiteSolid = '#FFFFFF'
const whiteMedHigh = hexToRGBA(whiteSolid, 0.35)
const whiteMed = hexToRGBA(whiteSolid, 0.2)
const whiteLowMed = hexToRGBA(whiteSolid, 0.1)
const whiteLow = hexToRGBA(whiteSolid, 0.04)

const blackSolid = '#000000'
const blackMedHigh = hexToRGBA(blackSolid, 0.35)
const blackMed = hexToRGBA(blackSolid, 0.2)
const blackLowMed = hexToRGBA(blackSolid, 0.1)
const blackLow = hexToRGBA(blackSolid, 0.04)

const dangerSolid = '#FD5252'
const dangerMedHigh = hexToRGBA(dangerSolid, 0.5)
const dangerMed = hexToRGBA(dangerSolid, 0.16)
const dangerLow = hexToRGBA(dangerSolid, 0.08)

const global = {
  team500,
  team600,
  market500,
  market600,
  commercial500,
  commercial600,
  product600,
  grey100,
  grey200,
  grey500,
  grey600,
  grey900,
  grey1000,
  whiteSolid,
  whiteMedHigh,
  whiteMed,
  whiteLowMed,
  whiteLow,
  blackSolid,
  blackMedHigh,
  blackMed,
  blackLowMed,
  blackLow,
  dangerSolid,
  dangerMedHigh,
  dangerMed,
  dangerLow
}

const light = {
  actionPrimary: blackMed,
  actionSecondary: blackLowMed,
  actionTertiary: blackLow,
  actionOutline: blackMed,
  actionDisabled: blackLow,
  fgHigh: blackSolid,
  fgMed: blackMedHigh,
  fgLow: blackMed,
  fgMedDanger: dangerMedHigh,
  fgHighDanger: dangerSolid,
  fgHighInvert: whiteSolid,
  fgMedInvert: whiteMedHigh,
  fgLowInvert: whiteMed,
  strokePrimary: blackMed,
  strokeSecondary: blackLowMed,
  strokeTertiary: blackLow,
  strokeSolid: grey500,
  strokeFocus: grey600,
  surfaceBg: grey200,
  surfaceFg: grey100,
  surfaceFgMed: blackLowMed,
  surfaceDangerHigh: dangerMed,
  surfaceDangerLow: dangerLow
}

const dark = {
  actionPrimary: whiteMed,
  actionSecondary: whiteLowMed,
  actionTertiary: whiteLow,
  actionOutline: whiteMed,
  actionDisabled: whiteLow,
  fgHigh: whiteSolid,
  fgMed: whiteMedHigh,
  fgLow: whiteMed,
  fgMedDanger: dangerMedHigh,
  fgHighDanger: dangerSolid,
  fgHighInvert: blackSolid,
  fgMedInvert: blackMedHigh,
  fgLowInvert: blackMed,
  strokePrimary: whiteMed,
  strokeSecondary: whiteLowMed,
  strokeTertiary: whiteLow,
  strokeSolid: grey600,
  strokeFocus: grey500,
  surfaceBg: grey1000,
  surfaceFg: grey900,
  surfaceFgMed: whiteLowMed,
  surfaceDangerHigh: dangerMed,
  surfaceDangerLow: dangerLow
}

export { global, light, dark }
