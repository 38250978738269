import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { spacesActions } from 'store/spaces'
import { setInitialState } from 'store/actions'
import { TimeEntry } from 'types/timeEntry'
import { authActions } from 'store/auth'

export type LogEntriesState = {
  loading: boolean
  nextCursor: TimeEntry['id'] | null
  ids: Array<TimeEntry['id']> | null
}

const initialState: LogEntriesState = {
  loading: true,
  nextCursor: null,
  ids: null
}

const logEntriesState = createSlice({
  name: 'logEntries',
  initialState,
  reducers: {
    fetchRequested: state => {
      state.loading = true
    },

    fetchSucceeded: state => {
      state.loading = false
    },

    fetchFailed: state => {
      state.loading = false
    },

    entriesReceived: (
      state: LogEntriesState,
      action: PayloadAction<{
        entries: TimeEntry[]
        nextCursor: TimeEntry['id'] | null
      }>
    ) => {
      state.nextCursor = action.payload.nextCursor
      state.ids = action.payload.entries.map(entry => entry.id)
    },

    entriesPageReceived: (
      state: LogEntriesState,
      action: PayloadAction<{
        nextCursor: TimeEntry['id'] | null
        entries: TimeEntry[]
      }>
    ) => {
      state.nextCursor = action.payload.nextCursor
      state.ids = Array.from(
        new Set([
          ...state.ids,
          ...action.payload.entries.map(entry => entry.id)
        ])
      )
    },

    entryWriteError: (
      state: LogEntriesState,
      action: PayloadAction<{
        entries: TimeEntry[]
        nextCursor: TimeEntry['id'] | null
      }>
    ) => {
      state.nextCursor = action.payload.nextCursor
      state.ids = action.payload.entries.map(entry => entry.id)
    },

    entryCreated: (
      state: LogEntriesState,
      action: PayloadAction<TimeEntry>
    ) => {
      const id = action.payload.id
      if (!state.ids.includes(id)) {
        state.ids.unshift(id)
      }
    },

    entryDeleted: (
      state: LogEntriesState,
      action: PayloadAction<TimeEntry['id']>
    ) => {
      state.ids = state.ids.filter(id => id !== action.payload)
    },

    entriesDeleted: (
      state: LogEntriesState,
      action: PayloadAction<TimeEntry['id'][]>
    ) => {
      state.ids = state.ids.filter(id => !action.payload.includes(id))
    }
  },

  extraReducers: builder => {
    builder
      .addCase(setInitialState, (state, action) => ({
        ...(action.payload.logEntries ? action.payload.logEntries : state)
      }))
      .addCase(authActions.userSignedOut, () => initialState)
      .addCase(spacesActions.selectedSpaceChanged, () => initialState)
  }
})

export const { actions, reducer } = logEntriesState
