import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from 'store/withPayloadType'
import { AuthState } from './auth/authState'
import { CategoriesState } from './categories'
import { LogEntriesState } from './logEntries/logEntriesSlice'
import { SearchState } from './search'
import { SettingsState } from './settings'
import { SpaceState } from './spaces/spacesState'
import { TagListState } from './tagList'
import { TagsState } from './tags/tagsSlice'
import { TimeEntriesState } from './timeEntries'

const ACTION_PREFIX = 'global/'

export const setInitialState = createAction(
  `${ACTION_PREFIX}setInitialState`,
  withPayloadType<
    Partial<{
      search: SearchState
      spaces: SpaceState
      auth: AuthState
      timeEntries: TimeEntriesState
      logEntries: LogEntriesState
      categories: CategoriesState
      tags: TagsState
      tagList: TagListState
      settings: SettingsState
    }>
  >()
)
