// Add darkmode variables in `prefers-color-scheme: dark` so
// that users with dark pref will not see light flash.

type DarkModeStyle = {
  '@media (prefers-color-scheme: dark)': {
    ':root:not(.light)': Record<string, string>
  }
}

type ThemeColors = Record<string, { value: string; variable: string }>

export const getDarkModeStyle = <Colors extends ThemeColors>(theme: {
  colors: Colors
}): DarkModeStyle => {
  const colorVariables: Record<string, string> = {}

  for (const name in theme.colors) {
    const { value, variable } = theme.colors[name]
    colorVariables[variable] = value

    // If values ever start with $ we can use this:
    // colorVariables[variable] = value.substring(0, 1) === '$' ? `$colors${value}` : value
  }

  return {
    '@media (prefers-color-scheme: dark)': {
      // `:not(.light)` allows them to force light
      ':root:not(.light)': colorVariables
    }
  }
}
