import { endOfWeek, startOfWeek } from 'date-fns'
import { SearchDatePreset } from 'types/search'

export type SearchState = {
  query: string
  tags: string[]
  dateStart: Date
  dateEnd: Date
  preset: SearchDatePreset
  people: string[]
}

export const initialState: SearchState = {
  dateStart: startOfWeek(new Date(), { weekStartsOn: 1 }),
  dateEnd: endOfWeek(new Date(), { weekStartsOn: 1 }),
  preset: 'week',
  query: '',
  tags: [],
  people: []
}
