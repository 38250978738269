import { Category } from 'types/category'
import { createSelector } from '@reduxjs/toolkit'
import { genericSelectors } from './categoriesSlice'
import { OdoStore } from 'store/store'
import { genericSelectors as tagGenericSelectors } from 'store/tags/tagsSlice'
import { Tag } from 'types/tag'
import { insertItemToTheStart, sortByRankAsc } from 'services/lexorank'

const categoriesSlice = (state: OdoStore) => state.categories
const tagsSlice = (state: OdoStore) => state.tags

const tagIdsByCategory = createSelector(tagsSlice, slice => {
  const tags = tagGenericSelectors
    .selectAll(slice)
    .filter(tag => !tag.archived && tag.categoryId)

  return tags.reduce<Record<string, Array<Tag['id']>>>((map, tag) => {
    if (map[tag.categoryId]) {
      map[tag.categoryId].push(tag.id)
    } else {
      map[tag.categoryId] = [tag.id]
    }

    return map
  }, {})
})

const tagsByCategory = createSelector(tagsSlice, slice => {
  const tags = tagGenericSelectors
    .selectAll(slice)
    .filter(tag => !tag.archived && tag.categoryId)

  return tags.reduce<Record<string, Array<Tag>>>((map, tag) => {
    if (map[tag.categoryId]) {
      map[tag.categoryId].push(tag)
    } else {
      map[tag.categoryId] = [tag]
    }

    return map
  }, {})
})

export const selectAllWithTagsEntities = createSelector(
  categoriesSlice,
  tagsByCategory,
  (categories, tagByCategory) => {
    const entities = genericSelectors.selectAll(categories)

    return entities.map(category => {
      return {
        id: category.id,
        name: category.name,
        color: category.color,
        rank: category.rank,
        tags: tagByCategory[category.id] ? tagByCategory[category.id] : []
      }
    })
  }
)

export const selectAllWithTags = createSelector(
  categoriesSlice,
  tagIdsByCategory,
  (categories, idsByCategory) => {
    const entities = genericSelectors.selectAll(categories)

    return entities.map(category => {
      return {
        id: category.id,
        name: category.name,
        color: category.color,
        rank: category.rank,
        tags: idsByCategory[category.id] ? idsByCategory[category.id] : []
      }
    })
  }
)

export const selectAllWithUncategorizedTags = createSelector(
  [selectAllWithTags, tagsSlice],
  (categories, slice) => {
    const uncategorizedTags = tagGenericSelectors
      .selectAll(slice)
      .filter(tag => !tag.archived && !tag.categoryId)
      .map(i => i.id)

    const rank = insertItemToTheStart(categories)

    return [
      {
        id: 'uncategorized',
        name: null,
        rank,
        tags: uncategorizedTags
      },
      ...categories
    ]
  }
)

export const selectAllWithUncategorizedTagsEntities = createSelector(
  [selectAllWithTagsEntities, tagsSlice],
  (categories, slice) => {
    const uncategorizedTags = tagGenericSelectors
      .selectAll(slice)
      .filter(tag => !tag.archived && !tag.categoryId)

    const rank = insertItemToTheStart(categories)

    return [
      {
        id: 'uncategorized',
        name: null,
        rank,
        tags: uncategorizedTags
      },
      ...categories
    ]
  }
)

export const categoriesColors = createSelector(categoriesSlice, categories => {
  return genericSelectors
    .selectIds(categories)
    .reduce<Record<Category['id'], Category['color']>>((map, categoryId) => {
      const color = genericSelectors.selectById(categories, categoryId).color
      if (color) map[categoryId] = color
      return map
    }, {})
})

// staged changes selectors

export const stagedChanges = createSelector(categoriesSlice, slice => {
  return Object.entries(slice.stagedChanges).map(([id, changes]) => ({
    id,
    changes
  }))
})

export const hasStagedChanges = createSelector(stagedChanges, changes =>
  Boolean(changes.length)
)

export const selectAllWithStagedChanges = createSelector(
  categoriesSlice,
  slice => {
    return genericSelectors
      .selectAll(slice)
      .map(category => {
        const changes = slice.stagedChanges[category.id]

        if (changes) {
          return {
            ...category,
            ...changes
          }
        }

        return category
      })
      .sort(sortByRankAsc)
  }
)
