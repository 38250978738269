import omit from 'lodash/omit'
import { createSelector } from '@reduxjs/toolkit'
import { genericSelectors } from './timeEntriesSlice'
import { OdoStore } from 'store'
import { TimeEntry } from 'types/timeEntry'

const timeEntriesSlice = (state: OdoStore) => state.timeEntries

export const selectByIdFactory = (id: TimeEntry['id']) => {
  return createSelector(timeEntriesSlice, slice => {
    return genericSelectors.selectById(slice, id)
  })
}
