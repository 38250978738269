import { createAction } from '@reduxjs/toolkit'
import { SpaceMembership } from 'types/membership'
import { withPayloadType } from '../withPayloadType'

const ACTION_PREFIX = 'space/'

export const selectedSpaceChanged = createAction(
  `${ACTION_PREFIX}selectedSpaceChanged`,
  withPayloadType<string>()
)

export const userSignedUp = createAction(
  `${ACTION_PREFIX}userSignedUp`,
  withPayloadType<SpaceMembership>()
)

export const fetchingMemberships = createAction(
  `${ACTION_PREFIX}fetchingMemberships`
)

export const membershipFetched = createAction(
  `${ACTION_PREFIX}membershipFetched`,
  withPayloadType<SpaceMembership[]>()
)

export const membershipFetchedError = createAction(
  `${ACTION_PREFIX}membershipFetchedError`
)

export const membershipUpdated = createAction(
  `${ACTION_PREFIX}membershipUpdated`,
  withPayloadType<SpaceMembership>()
)

export const workspaceCreated = createAction(
  `${ACTION_PREFIX}workspaceCreated`,
  withPayloadType<SpaceMembership>()
)

export const userJoinedToTeam = createAction(
  `${ACTION_PREFIX}userJoinedToTeam`,
  withPayloadType<SpaceMembership>()
)
