import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './languages/en.json'
import es from './languages/es.json'
// import fr from './languages/fr.json'
// import ge from './languages/ge.json'

export const NAMESPACE = 'translation'

const resources = {
  en: { [NAMESPACE]: en },
  es: { [NAMESPACE]: es }
  // fr: { [NAMESPACE]: fr },
  // ge: { [NAMESPACE]: ge }
}

export const languages = Object.keys(resources)

const english = 'en'

i18n.use(detector).use(initReactI18next)

export const i18nInit = language => {
  i18n.init({
    lng: language,
    fallbackLng: english,
    resources
  })

  i18n.services.formatter.add('time', (seconds, lng, options) => {
    if (seconds < 0) return '—'

    const hours = Math.floor(seconds / 3600)
    seconds %= 3600
    const minutes = Math.floor(seconds / 60)
    seconds %= 60

    switch (options.format) {
      case 'default': {
        const suffix = options.showSeconds ? ` ${seconds}s` : ''
        if (hours > 0) return `${hours}h ${minutes}m` + suffix
        if (minutes > 0) return `${minutes}m` + suffix
        return `${seconds}s`
      }
      case 'subscript': {
        const suffix = options.showSeconds ? ` ${seconds}\u209B` : ''
        if (hours > 0) return `${hours}\u2095 ${minutes}\u2098` + suffix
        if (minutes > 0) return `${minutes}\u2098` + suffix
        return `${seconds}\u209B`
      }
    }
  })

  return i18n
}
export default i18n
