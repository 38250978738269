import { PayloadAction } from '@reduxjs/toolkit'
import deepEqual from 'fast-deep-equal'
import isEmpty from 'lodash.isempty'

export const draftUpdatesInitialState = {
  stagedChanges: {}
}

export const draftUpdatesReducers = {
  draftUpdatesPushed: (
    state,
    {
      payload
    }: PayloadAction<{ id: string; changes: Partial<Record<string, unknown>> }>
  ) => {
    const { id, changes } = payload
    const currentValues = state.entities[id]

    const partial = Object.assign({}, state.stagedChanges[id])

    Object.entries(changes).map(([key, value]) => {
      if (deepEqual(currentValues[key], value)) {
        delete partial[key]
      } else {
        partial[key] = value
      }
    })

    if (isEmpty(partial)) {
      delete state.stagedChanges[id]
    } else {
      state.stagedChanges[id] = partial
    }
  },

  draftUpdatesCommited: state => {
    state.stagedChanges = {}
  },

  draftUpdatesCancelled: state => {
    state.stagedChanges = {}
  }
}
