import React, { createContext, useContext, useEffect, useState } from 'react'

const MediaContext = createContext()

export const MediaProvider = ({ children }) => {
  const smMediaQuery =
    process.browser && window.matchMedia('(max-width: 640px)')
  const mdMediaQuery =
    process.browser &&
    window.matchMedia('(min-width: 641px) and (max-width: 992px)')
  const [matchesQuery, setMatchesQuery] = useState({
    sm: smMediaQuery?.matches,
    md: mdMediaQuery?.matches
  })

  useEffect(() => {
    const handleChange = () => {
      const sm = smMediaQuery.matches
      const md = mdMediaQuery.matches
      setMatchesQuery({ sm, md })
    }

    smMediaQuery.addEventListener('change', handleChange)
    mdMediaQuery.addEventListener('change', handleChange)

    return () => {
      smMediaQuery.removeEventListener('change', handleChange)
      mdMediaQuery.removeEventListener('change', handleChange)
    }
  }, [smMediaQuery, mdMediaQuery])

  return (
    <MediaContext.Provider value={{ sm: matchesQuery.sm, md: matchesQuery.md }}>
      {children}
    </MediaContext.Provider>
  )
}

/** @deprecated: avoid using this hook, find a solution with CSS */
const useMedia = () => useContext(MediaContext)

export default useMedia
