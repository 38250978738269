import { ApiError, isApiError } from 'utils/errors'
import { supabase } from './supabase'

type Options = {
  /**
   * Refresh the supabase session (if expired) before making the request.
   */
  checkSession: boolean
  throwOnError: boolean
}

const DEFAULT_OPTIONS: Options = {
  checkSession: false,
  throwOnError: false // @todo: opt in for now, but should be default true.
}

export async function apiCall<R, P = Object>(
  path: string,
  body: P,
  options?: Partial<Options>
) {
  const opts = Object.assign({}, DEFAULT_OPTIONS, options)

  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  }

  if (opts.checkSession) {
    // Refreshes session if necessary
    await supabase.auth.getSession()
  }

  const response = await fetch('/api/' + path, {
    method: 'POST',
    headers,
    credentials: 'same-origin',
    body: JSON.stringify(body)
  })

  const data: R = await response.json()

  if (opts.throwOnError && response.status >= 400 && isApiError(data)) {
    throw new ApiError(data.name, data.status, data.message)
  }

  return {
    ...response,
    data
  }
}
