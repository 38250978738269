import { createContext, Dispatch, useContext, useReducer } from 'react'

type LinkMetadata = {
  cachedUrlMetadata: CachedMetadata
}

export type CachedMetadata = Record<
  string,
  { title: (pathname: string) => string; favicon: string }
>
const Well_Known_Hosts: CachedMetadata = {
  github: {
    title: (pathname?: string) => {
      if (pathname) {
        const match = pathname.match(
          /^\/([^\/]+)\/([^\/]+)\/(pull|issues)\/(\d+)/
        )

        if (match) {
          const [, owner, repo, type, id] = match
          if (type === 'pull') {
            return `GitHub | ${owner}/${repo} PR #${id}`
          } else {
            return `GitHub | ${owner}/${repo} Issue #${id}`
          }
        }

        const splittedPathname = pathname.split('/')

        // We should test more exhaustively but last two params should have info of the
        // issue / pr and the number
        const lastParam = splittedPathname[splittedPathname.length - 1]
        const secondLastParam = splittedPathname[splittedPathname.length - 2]

        if (!isNaN(parseInt(lastParam, 10))) {
          return `GitHub | ${secondLastParam
            .charAt(0)
            .toUpperCase()}${secondLastParam.substring(1)} - ${lastParam}`
        }

        return `GitHub | ${lastParam}`
      }
      return 'GitHub'
    },
    favicon: 'https://github.githubassets.com/favicons/favicon.svg'
  },
  height: {
    title: (pathname?: string) => {
      if (pathname) {
        const splittedPathname = pathname.split('/')
        const lastParam = splittedPathname[splittedPathname.length - 1]

        if (lastParam.includes('T-')) {
          return `Height | ${lastParam}`
        }
      }

      return 'Height'
    },
    favicon: 'https://p22.height.app/a/statics/assets/favicon.JUZPHONN.png'
  }
}

const reducer = (
  state: CachedMetadata,
  action: { url: string; favicon: string; title: string }
): CachedMetadata => {
  if (action.url) {
    return {
      ...state,
      [action.url]: {
        title: () => action.title,
        favicon: action.favicon
      }
    }
  }

  return state
}

const LinkMetadataContext = createContext<LinkMetadata>({} as LinkMetadata)

export let addMetadataToCache: Dispatch<{
  url: string
  favicon: string
  title: string
}>

export function LinkMetadataProvider({ children }) {
  const [cachedUrlMetadata, dispatch] = useReducer(reducer, Well_Known_Hosts)

  // We do this so we can dispatch actions from simple functions withouth necessity of being a hook that imports the context
  // We should have care of calling this function when the context is already mounted
  addMetadataToCache = dispatch

  return (
    <LinkMetadataContext.Provider
      value={{
        cachedUrlMetadata
      }}
    >
      {children}
    </LinkMetadataContext.Provider>
  )
}

export function useLinkMetadataContext() {
  return useContext(LinkMetadataContext)
}
