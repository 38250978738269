import { ComponentProps, ElementType, FunctionComponent } from 'react'
// Base Scaffold theme
import { createStitches, CSS as CSSType } from '@stitches/react'
import { dark, global, light } from './colors'
import { globalMetrics, large, small } from './metrics'
import {
  typographyGlobal,
  typographyLarge,
  typographySmall
} from './typography'
import { utils } from './utils'

export const {
  styled,
  css,
  keyframes,
  theme,
  createTheme,
  getCssText,
  globalCss,
  config
} = createStitches({
  theme: {
    colors: {
      ...light,
      ...global
    },

    sizes: {
      ...small.sizes,
      ...globalMetrics.sizes
    },

    space: {
      ...small.space
    },

    radii: {
      ...globalMetrics.radii
    },

    fonts: { oracleTriple: 'ABCOracleTriple', octave: 'Octave' },

    fontSizes: {
      ...typographySmall.fontSize
    },

    letterSpacings: {
      tight: '-0.005em', // -0.005%
      base: '-0.01em' // -0.01%
    },

    lineHeights: {
      ...typographyGlobal.lineHeight
    },

    fontWeights: {
      normal: 400,
      medium: 500,
      bold: 700
    },

    borderWidths: {
      default: '1px',
      focus: '2px',
      control: '0.14rem', // button, input, select, etc
      panel: '$borderWidths$default' // modals, menus, etc.
    },

    // borderStyles: {},

    shadows: {
      levelOne: `0 2px 8px ${global.blackMed}`,
      levelTwo: `0 4px 16px ${global.blackMed}`
    },

    zIndices: {
      navigation: 10,
      modal: 15,
      popover: 20,
      toast: 25
    }

    // transitions: {}
  },
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 1024px)'
  },
  utils
})

export const darkTheme = createTheme('dark', {
  colors: {
    ...dark,
    ...global
  }
})

// You have to call this for the theme CSS to be output
darkTheme.toString()

export const desktop = createTheme({
  sizes: {
    ...large.sizes,
    ...globalMetrics.sizes
  },
  fontSizes: {
    ...typographyLarge.fontSize
  },
  space: {
    ...large.space
  },
  zIndices: {
    navigation: '10',
    modal: '15',
    popover: '20',
    toast: '25'
  }
})

export type StyledProps<T extends FunctionComponent | ElementType> =
  ComponentProps<T> & {
    as?: ElementType
  }
export type CSS = CSSType<typeof config>
export type ThemeMap = typeof theme
