const letterSpacing0 = '0%'
const paragraphSpacing0 = '0'

const lineHeight1 = '105%'
const lineHeight2 = '111%'
const lineHeight3 = '121%'
const lineHeight4 = '124%'

const scaleBody = 0.4
const scaleDisplay = 2

export const typographyGlobal = {
  letterSpacing: {
    letterSpacing0,
    paragraphSpacing0
  },
  lineHeight: {
    1: lineHeight1,
    2: lineHeight2,
    3: lineHeight3,
    4: lineHeight4
  }
}

const typographyLargeBase = 1.8
const largeValue = typographyLargeBase + 2 * scaleBody

export const typographyLarge = {
  fontSize: {
    xsmall: '0.8rem',
    small: '1.4rem',
    base: `${typographyLargeBase}rem`,
    large: `${largeValue}rem`,
    extraLarge: `${largeValue * scaleDisplay}rem`
  }
}

const smallValue = 1.2
const smallBase = smallValue + scaleBody
const smallLarge = smallBase + scaleBody

export const typographySmall = {
  fontSize: {
    small: `${smallValue}rem`,
    base: `${smallBase}rem`,
    large: `${smallLarge}rem`,
    extraLarge: `${smallLarge * scaleDisplay}rem`
  }
}
