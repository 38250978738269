import { initialState, SpaceState } from './spacesState'
import { createReducer } from '@reduxjs/toolkit'
import { authActions } from 'store/auth'
import * as actions from './spacesActions'
import { setInitialState } from 'store/actions'

export const spaceReducer = createReducer<SpaceState>(initialState, builder =>
  builder
    .addCase(setInitialState, (state, action) => ({
      ...(action.payload.spaces ? action.payload.spaces : state)
    }))
    .addCase(authActions.userSignedOut, () => initialState)
    .addCase(actions.selectedSpaceChanged, (state, action) => ({
      ...state,
      currentSpaceId: action.payload
    }))
    .addCase(actions.fetchingMemberships, (state, action) => ({
      ...state,
      loading: true
    }))
    .addCase(actions.membershipFetched, (state, action) => ({
      ...state,
      memberships: action.payload,
      loading: false
    }))
    .addCase(actions.membershipFetchedError, (state, action) => ({
      ...state,
      loading: false
    }))
    .addCase(actions.membershipUpdated, (state, action) => ({
      ...state,
      memberships: [
        ...state.memberships.filter(
          membership => membership.id !== action.payload.id
        ),
        action.payload
      ]
    }))
    .addCase(actions.workspaceCreated, (state, action) => ({
      ...state,
      memberships: [...state.memberships, action.payload]
    }))
    .addCase(actions.userSignedUp, (state, action) => ({
      ...state,
      memberships: [action.payload]
    }))
    .addCase(actions.userJoinedToTeam, (state, action) => ({
      ...state,
      memberships: [...state.memberships, action.payload],
      currentSpaceId: action.payload.space.id
    }))
)
