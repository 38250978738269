import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useOdoDispatch, useOdoSelector } from 'store'
import { spacesActions, spacesSelectors } from 'store/spaces'
import { spaceChangeRequested } from 'store/thunkActions'

export const useUpdateUrlWithSpace = () => {
  const router = useRouter()
  const dispatch = useOdoDispatch()

  const spaceIds = useOdoSelector(spacesSelectors.spaceIds)

  // Ensure that the effects only run when the spaceId of the URL changes.
  const urlSpaceId =
    router.route.includes('[spaceId]') &&
    typeof router.query.spaceId === 'string'
      ? router.query.spaceId
      : undefined

  // Keep in sync the spaceId in the URL with the redux state.
  useEffect(() => {
    if (urlSpaceId) {
      dispatch(spaceChangeRequested(urlSpaceId))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSpaceId])

  // If for some reason the spaceId on the url is not a user's spaces, redirect to the /404 page.
  useEffect(() => {
    if (urlSpaceId && spaceIds.length > 0 && !spaceIds.includes(urlSpaceId)) {
      // Use the singleton instance of router to push this URL.
      // This prevents having router as a dependency.
      Router.push('/404')
    }
  }, [spaceIds, urlSpaceId])
}
